<style scoped>
.two-column .card .header {
  background: url("../../../assets/imgs/blogs/blog-banner-1.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 0 4rem;
}
</style>
<template>
  <a-row class="two-column">
    <a-col
      :sm="{ span: 12 }"
      :md="{ span: 12 }"
      :lg="{ span: 12 }"
      class="card"
    >
      <div class="header justify-end">
        <div class="category-name">STATISTICS</div>
        <a-divider />
      </div>
      <div class="footer">
        <h1>25+ Mobile Stats To Know In 2020</h1>

        <p>
          At Gap Dynamics, we love all things tech. That's why we're giving you
          25+ mobile stats that will shift your perspective…
        </p>

        <p class="author-text">By Admin</p>
      </div>
    </a-col>
    <a-col
      :sm="{ span: 12 }"
      :md="{ span: 12 }"
      :lg="{ span: 12 }"
      class="card"
    >
      <div class="header justify-end">
        <div class="category-name">STATISTICS</div>
        <a-divider />
      </div>
      <div class="footer">
        <h1>25+ Mobile Stats To Know In 2020</h1>

        <p>
          At Gap Dynamics, we love all things tech. That's why we're giving you
          25+ mobile stats that will shift your perspective…
        </p>

        <p class="author-text">By Admin</p>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import "./style.scss";
export default {
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
};
</script>
