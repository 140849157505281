<style scoped>
.banner {
  background: linear-gradient(0deg, rgba(98, 98, 98, 0.3), rgba(98, 0, 0, 0.3)),
    url("../assets/imgs/blogs/blog-banner-1.png");
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  display: flex;
  align-items: center;
}

.three-column .card .header {
  background: url("../assets/imgs/blogs/blog-banner-1.png");
  height: 53%;
  background-size: cover;
  background-position: center;
}
.five-col-carousel .card .header {
  background: url("../assets/imgs/blogs/blog-banner-1.png");
  height: 30%;
  background-size: cover;
  background-position: center;
}
.ant-divider {
  background: #6b728030;
}
.ant-divider-horizontal {
  height: 3px;
}
.post_category {
  font-size: 1.1rem;
  margin-top: -6px;
}
.description {
  font-size: 20px;
  font-weight: normal;
}
.right {
  font-size: initial;
}
.form-sub-button {
  cursor: pointer;
  font-family: "UnitedSansCondHeavy";
  height: 49px;
  background-color: #ae0000;
  border-color: #ae0000;
  color: #fff;
  font-size: 1.3rem;
}
.connect-icon {
  display: inline-block;
  height: 3.8rem;
  position: relative;
  vertical-align: middle;
  width: 3.6rem;
  margin-right: 40px;
}
.connect-icon .connect-border {
  border: 3px solid #ae0000;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.3s cubic-bezier(0.625, 0.125, 0.305, 0.875);
}
.connect-icon svg .connect-knockout {
  fill: #fff;
}
.posts__social {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
</style>
<template>
  <div class="page" id="blog">
    <Animation3 />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <a-row class="mx-8 my-8 items-center">
      <!-- <a-col
        :sm="{ span: 5 }"
        :md="{ span: 5 }"
        :lg="{ span: 1 }"
        :xlg="{ span: 2 }"
      >
        <div class="row-title">Recent</div>
      </a-col> -->
      <!-- <a-col
        :sm="{ span: 16 }"
        :md="{ span: 16 }"
        :lg="{ span: 21 }"
        :xlg="{ span: 2 }"
      >
        <a-divider />
      </a-col> -->
      <!-- <a-col :span="3"> -->
      <!-- <div class="arrows flex">
          <a class="posts__arrow mr-2" href="#">
            <i class="fas fa-caret-left" style="color: #000;font-size: 47px;"></i>
          </a>
          <a class="posts__arrow ml-2" href="#">
            <i class="fas fa-caret-right" style="color: #000;font-size: 47px;"></i>
          </a>
      </div>-->
      <!-- </a-col> -->
    </a-row>
    <!-- add carousel here -->
    <!-- <Carousel /> -->
    <!-- <div class="five-col-carousel">
      <div class="card">
        <div class="header"></div>
        <div class="footer">
          <p class="category-name">App of the week</p>
          <br />
          <h1>25+ Mobile Stats To Know In 2020</h1>

          <p class="author-text">
            By
            <strong>Admin</strong>
            <br />
            <i>on 4 May 2021</i>
          </p>
        </div>
      </div>
    </div>-->

    <!-- <a-row class="mx-8 my-8 items-center">
      <a-col :sm="{ span: 6 }" :md="{ span: 2 }" :lg="{ span: 1 }">
        <div class="row-title">Popular</div>
      </a-col>
      <a-col :sm="{ span: 15 }" :md="{ span: 22 }" :lg="{ span: 21 }">
        <a-divider />
      </a-col> -->
    <!-- <a-col :span="3"> -->
    <!-- <div class="arrows flex">
          <a class="posts__arrow mr-2" href="#">
            <i class="fas fa-caret-left" style="color: #000;font-size: 47px;"></i>
          </a>
          <a class="posts__arrow ml-2" href="#">
            <i class="fas fa-caret-right" style="color: #000;font-size: 47px;"></i>
          </a>
      </div>-->
    <!-- </a-col> -->
    <!-- </a-row> -->
    <!-- add carousel for Popular  -->
    <!-- <Carousel /> -->
    <!-- <div class="popular-three-column">
      <div class="card">
        <p class="category-name">App Development, Featured</p>
        <div class="footer">
          <h1>25+ Mobile Stats To Know In 2020</h1>
          <p>At Fueled, we love all things tech. That's why we're giving you 25+ mobile stats that will shift your perspective…</p>

          <p class="author-text">
            By
            <strong>Admin</strong>
            <br />
            <i>on 4 May 2021</i>
          </p>
        </div>
      </div>
    </div>-->

    <!-- <a-row class="bg-white my-8 py-8"> -->
    <!-- <a-col
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 16 }"
        :xlg="{ span: 17 }"
      > -->
    <!-- <a-row class="items-center mx-8">
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 2 }"
            :xlg="{ span: 3 }"
          >
            <div class="row-title">Recent</div>
          </a-col>
          <a-col
            :sm="{ span: 15 }"
            :md="{ span: 15 }"
            :lg="{ span: 22 }"
            :xlg="{ span: 21 }"
          >
            <a-divider />
          </a-col>
        </a-row> -->
    <!-- <div class="card mx-8">
          <div class="two-column-list flex justify-between">
            <div class="left">
              <img class="post_img" :src="BlogImg" />
            </div>
            <div class="middle">
              <p class="post_category">App Of the Week</p>
              <h1 class="post_heading">
                Nudget Gives You That Little Push To Create a Better Budget
              </h1>
              <p class="description">
                Yes, I’ve heard the news that streaming is in!…
              </p>
            </div>

            <div class="right">
              <p class="author-text">
                By
                <strong>Admin</strong>
                <br />on May 3, 2021
              </p>
            </div>
          </div>
          <div class="flex justify-between my-4">
            <button src="#" class="pagination-btns" type="submit">
              <span
                style="margin-right: 10px;"
                class="meta-nav"
                aria-hidden="true"
                >←</span
              >
              Older
            </button>
            <button src="#" class="pagination-btns" type="submit">
              Newer
              <span
                class="meta-nav"
                style="    margin-left: 10px;"
                aria-hidden="true"
                >→</span
              >
            </button>
          </div>
        </div>
      </a-col> -->
    <!-- <a-col
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 8 }"
        :xlg="{ span: 8 }"
      >
        <a-row class="items-center mx-8">
          <a-col>
            <div class="row-title">Popular Categories</div>
          </a-col> -->
    <!-- <a-col>
            <a-divider />
          </a-col> -->
    <!-- </a-row> -->

    <!-- <a-row class="items-center mx-8">
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
            :xlg="{ span: 6 }"
          >
            <div class="row-title2 mx-2 my-3">CATEGORY 1</div>
          </a-col>
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
            :xlg="{ span: 6 }"
          >
            <div class="row-title2 mx-2 my-3">CATEGORY 1</div>
          </a-col>
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
            :xlg="{ span: 6 }"
          >
            <div class="row-title2 mx-2">CATEGORY 1</div>
          </a-col>
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
            :xlg="{ span: 6 }"
          >
            <div class="row-title2 mx-2 my-3">CATEGORY 1</div>
          </a-col>
          <a-col
            :sm="{ span: 6 }"
            :md="{ span: 6 }"
            :lg="{ span: 6 }"
            :xlg="{ span: 6 }"
          >
            <div class="row-title2 mx-2 my-3">CATEGORY 1</div>
          </a-col>
        </a-row> -->
    <!-- search -->
    <!-- <a-row class="items-center mx-8 my-12">
          <div class="input-placeholder w-full">
            <input
              class="form-input"
              type="text"
              name="search"
              placeholder="Search for blog"
            />
          </div>
        </a-row>
        <a-row class="items-center mx-8 my-12">
          <div class="card w-full">
            <div class="email-box bg-black py-8 px-4">
              <p class="label text-white">Email Signup</p>
              <input
                class="email-form-input bg-white p-4 my-2"
                type="text"
                name="name"
                placeholder="Name"
              />
              <input
                class="email-form-input bg-white p-4 my-2"
                type="email"
                name="email"
                placeholder="Email"
              />

              <div class="flex justify-end">
                <button class="form-sub-button px-4" type="submit">
                  Submit
                </button>
              </div>
            </div>

            <button class="form-sub-button w-full" type="submit">
              JOBS AT GAP DYNAMICS
            </button>
            <div class="posts__social">
              <div class="connect-icon">
                <div class="connect-border"></div>
                <TwitterIcon />
              </div>

              <div class="connect-icon">
                <div class="connect-border"></div>
                <FacebookIcon />
              </div>

              <div class="connect-icon">
                <div class="connect-border"></div>
                <GoogleIcon />
              </div>
            </div>
          </div>
        </a-row>
      </a-col> -->
    <!-- </a-row> -->
  </div>
</template>

<script>
import Section1 from "@/components/blog/section1";
import Section2 from "@/components/blog/section2";
import Section3 from "@/components/blog/section3";
// import Carousel from "@/components/blogDetails/carousel";
// import FacebookIcon from "@/assets/icons/blog_facebook.svg";
// import GoogleIcon from "@/assets/icons/blog_google.svg";
// import TwitterIcon from "@/assets/icons/blog_twitter.svg";
import img from "@/assets/imgs/blogs/single_blog.jpg";
import Animation3 from "@/components/Animation3";
export default {
  components: {
    Section1,
    Section2,
    Section3,
    // Carousel,
    // FacebookIcon,
    // GoogleIcon,
    // TwitterIcon,
    Animation3,
  },
  data: function() {
    return {
      BlogImg: img,
    };
  },
  // created() {
  //   document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  // },
};
</script>
