<template>
  <div class="banner img">
    <div class="container py-20 text-center">
      <p class="category-name-text ">Category Name</p>
      <h1>HOW TO GET INVESTOR FOR YOUR APP</h1>
      <p class="banner-description">
        You have a mobile app idea but lack the funds to get the ball rolling.
        Here's some valuable information on how to get investors to…
      </p>
    </div>
  </div>
</template>
<script>
import "./style.scss";
export default {
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
};
</script>
